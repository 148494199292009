<template>
<div class="scope">
    <img :src="novel.novel_cover" class="cover mb-4">
    <p> ตอนที่ {{novel.last_ep}} : {{novel.chapter_name}} </p>
    <hr>
    <p>ราคา : {{novel.chapter_price | numFormat}} ทอง</p>
    <div class="myCoin mb-3">
        <table style="width:100%">
            <tr>
                <td>ทองที่มีทั้งหมด</td>
                <td><img src="./../../assets/price.png" width="18px" /> <span>{{userInfo.member_gold| numFormat}}</span></td>
            </tr>
        </table>
    </div>
    <b-alert :show="error" variant="warning">{{err_text}}</b-alert>
    <div v-if="ishide">
        <p class="warn">ยกเลิกการขายแล้ว</p>
    </div>
    <div v-else-if="goldEnough">
        <p class="addCoin" @click="buyChapter(novel)">ซื้อเลย</p>
    </div>
    <div class="text-center" v-else>
        <p class="warn">ทองของคุณไม่เพียงพอ</p>
        <p class="addCoin" @click="goToPayment">เติมทอง</p>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import {
    mapActions,
    mapGetters
} from 'vuex'
import router from './../../router/index'

export default {
    props: ['novel'],
    data() {
        return {
            goldEnough: false,
            error: false,
            err_text: "",
            ishide: false,
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
        }
    },
    mounted() {
        //console.log('buy : ',this.novel)
        if (parseInt(this.userInfo.member_gold) >= parseInt(this.novel.chapter_price)) {
            this.goldEnough = true
        }
        if(this.novel.ishide == true){
            this.ishide = true
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'GET_USER_INFO'
        })
    },
    methods: {
        ...mapActions(['SET_MEMBER_GOLD']),
        goToPayment() {
            router.push("/payment");
        },
        async buyChapter(novel) {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append('chapter_id', novel.chapter_id);
            //console.log('chapter_id : ',formData.get('chapter_id'))
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&action=buychapter', formData)
                    .then((res) => {
                        if (res.data.status == 200) {
                            this.SET_MEMBER_GOLD(res.data.member_gold)
                            if(novel.closeModal){
                                //console.log('trigger to parent')
                                this.$emit("closeModal");
                            }else{
                                router.push("/novel/" + novel.novel_id + "/chapter/" + novel.chapter_id)
                            }
                        }
                        else if(res.data.status == "500") {
                            this.error = true
                            this.err_text = "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลที่เพจเฟสบุ๊ค"
                        }
                        else if(res.data.status == "403") {
                            router.push("/novel/" + novel.novel_id + "/chapter/" + novel.chapter_id_first)
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        }
    }
}
</script>

<style scoped>
.scope {
    padding: 5% 10% 0.2% 10%;
    text-align: center;
}

.cover {
    border-radius: 10px;
    width: 6rem;
}

.myCoin {
    padding: 5px;
    border: 1px solid #EE806B;
    border-radius: 7px;
    text-align: center;
    width: 100%;
}

.warn {
    font-size: 14px;
    color: #ffffff;
    padding: 0px 5px;
    border-radius: 5px;
    background-color: #042446;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.addCoin {
    color: #ffffff;
    font-weight: bold;
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
    background-color: #EE806B;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.addCoin:hover {
    background-color: rgb(235, 116, 92);
    cursor: pointer;
}
</style>
