<template>
<div class="scope">
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true">
        <Login />
    </b-modal>
    <b-modal v-model="showBuyChapterModal" :hide-header="true" :hide-footer="true">
        <BuyChapterModal :novel="detailForModal" />
    </b-modal>
    <!--  -->
    <p class="pageTopic">ค้นหา: {{topic}}</p>

    <b-row class="fiveNovelList">
        <b-col v-if="isLoadData">
            <div class="text-center">
                <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
            </div>
        </b-col>
        <b-col v-else cols="6" sm="3" lg="2" v-for="(novel,index) in novels" :key="index">
            <a class="text-decoration-none text-default-n" :href="'/novel/' + novel.novel_id" v-on:click="goToNovelOrChapter(novel, $event)">
                <b-card img-top tag="article" class="mb-5 bookCardSize">
                    <b-card-text class="triangle" v-if="novel.chapter_price==0">
                        <div class="freeText">ฟรี</div>
                    </b-card-text>
                    <b-card-text class="small text-center noOfEpisode">
                        <div>{{novel.last_ep}}</div>
                    </b-card-text>
                    <div class="novelCover2" :style="{backgroundImage: 'url(\''+novel.novel_cover+'\') '}"></div>
                    <b-card-sub-title class="mb-2 novelName text-center">
                        <v-clamp autoresize :max-lines="1">{{novel.novel_title}}</v-clamp>
                    </b-card-sub-title>
                    <b-card-text class="small text-center">
                        ตอนที่ {{novel.last_ep}}
                    </b-card-text>
                </b-card>
            </a>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios'
import Login from "./../pages/Login"
import BuyChapterModal from "./../elements/BuyChapterModal"
import VClamp from 'vue-clamp'
import router from './../../router/index'
import {
    mapGetters,
    mapActions
} from 'vuex'
export default {
    data() {
        return {
            topic: "",
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            showLoginModal: false,
            showBuyChapterModal: false,
            detailForModal: {}
        }
    },
    mounted() {
        this.SET_LOADING_STATUS(true)
        this.setTopic(this.$route.params.type)
        this.SET_SEARCH_NOVEL(this.$route.params.type)

    },
    watch: {
        mode() {
            this.checkMode()
        },
        novels() {
            //console.log('novel change')
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters({
            novels: 'GET_ALL_NOVEL',
            loginStatus: 'GET_LOGIN_STATUS',
            isLoadData: 'GET_LOADING',
            mode: 'GET_MODE'
        })
    },
    components: {
        VClamp,
        Login,
        BuyChapterModal
    },
    methods: {
        ...mapActions(['SET_SEARCH_NOVEL', 'SET_LOADING_STATUS']),
        setTopic(topicDefault) {
            if (topicDefault == 'translateNovel') {
                this.topic = 'นิยายแปลตอนล่าสุดทั้งหมด'
            } else if (topicDefault == 'createNovel') {
                this.topic = 'นิยายแต่งตอนล่าสุดทั้งหมด'
            } else if (topicDefault == 'promotionNovel') {
                this.topic = 'นิยายจัดโปรโมชันตอนล่าสุดทั้งหมด'
            }
            //document.title = this.topic
        },
        goToNovelOrChapter(novel, e) {
            e.preventDefault();
            router.push("/novel/" + novel.novel_id)
            return false
        },
        async checkBuyChapter(novel) {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append('chapter_id', novel.chapter_id);
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&submodule=buycheck', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if (res.data.status == 200) {
                            router.push("/novel/" + novel.novel_id + "/chapter/" + novel.chapter_id);
                        } else if (res.data.status == 404) {
                            this.detailForModal = novel
                            this.detailForModal['member_gold'] = res.data.member_gold
                            this.showBuyChapterModal = true
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('bookCardSize').forEach(element => {
                element.classList.remove('darkmode')
            });
            document.getElementsByClassName('novelName').forEach(element => {
                element.classList.remove('darkmodeText')
            });
        },
        darkmode() {
            setTimeout(() => {
                document.getElementsByClassName('bookCardSize').forEach(element => {
                    element.classList.add('darkmode')
                });
                document.getElementsByClassName('novelName').forEach(element => {
                    element.classList.add('darkmodeText')
                });
            }, 10);
        }
    },
};
</script>

<style scoped>
.scope {
    padding: 3% 10% 5% 10%;
}

.pageTopic {
    font-size: 20px;
    font-weight: bold;
}

.text-default-n{
    color: inherit;
}

.bookCardSize {
    border-color: #ffffff;
    border-radius: 0px;
    margin: auto;
    transform: translateZ(0px);
    transition-duration: .5s;
}

.bookCardSize:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.fiveNovelList {
    padding-top: 2%;
    padding-bottom: 1%;
}

.novelCover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.card-body {
    padding: 10px;
}

div.col {
    transform: translateZ(0px);
    transition-duration: .5s;
}

div.col:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.novelName {
    font-size: 16 !important;
    color: #000000 !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
}

.noOfEpisode {
    color: #ffffff;
    font-size: 10px;
    position: absolute;
    top: 10px;
    left: 16px;
    background-color: #EE806B;
    border-radius: 0px 0px 6px 6px;
    width: 26px;
    height: 28px;
    padding-top: 8px;
}

.triangle {
    border-style: solid;
    border-width: 0 34px 34px 0;
    border-color: transparent #849eb4 transparent transparent;
    position: absolute;
    right: -1px;
    top: -1px;
}

.freeText {
    position: absolute;
    right: -30px;
    top: 1px;
    color: #ffffff;
    font-size: 13px;
    transform: rotate(45deg);
}

@media screen and (max-width: 500px) {
    .scope[data-v-57109d03] {
        padding: 3% 7% 5% 7%;
    }

    .pageTopic {
        font-size: 14px;
    }

    .novelCover {
        width: 6.75rem;
        height: 10rem;
    }

    .bookCardSize {
        width: 8.1rem;
    }

}

@media screen and (min-width: 1441px) {
    .scope {
        padding: 3% 20% 5% 20%;
    }
}

.darkmode {
    border-color: #35363A;
    background-color: #35363A;
}

.darkmodeText {
    color: #ffffff !important;
}
.novelCover2{
    width: 100%;
    padding-top: 144%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
